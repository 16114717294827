import _Vue from "vue";
import dayjs from 'dayjs'


export function dayJsPlugin<dayJsPluginOptions>(Vue: typeof _Vue, options?: dayJsPluginOptions): void {
    // do stuff with options
    Vue.prototype.$dayjs = dayjs
}

declare module "vue/types/vue" {
    interface Vue {
        $dayjs: typeof dayjs
    }

    interface VueConstructor {
        $dayjs: typeof dayjs
    }
}