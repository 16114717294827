import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import it from 'vuetify/lib/locale/it';

import '../icons/font-awesome/all.min.css'
import '../assets/style/main.scss'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#e91e63',
                secondary: '#ffc107',
                accent: '#ff5722',
                error: '#f44336',
                warning: '#ff9800',
                info: '#607d8b',
                success: '#4CAF50',
                primaryLight: '#ffc0e7'
            },
            dark: {
                primary: '#007BFF',
                secondary: '#424242',
                secondaryLight: '#c1c1c1',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                background: '#424242'
            },
        },
    },
    lang: {
        locales: { it },
        current: 'it',
    },
    icons: {
        iconfont: 'fa',
    },
});
