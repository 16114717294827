import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import dashboardLayout from '../layouts/dashboardLayout.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/dashboard',
    component: dashboardLayout,
    children: [
      {
        path: 'system/variables',
        name: 'systemVariablesView',
        component: () => import(/* webpackChunkName: "about" */ '../views/system/systemVariablesView.vue')
      },
      {
        path: 'system/variables/emissions-omologations',
        name: 'systemOmologationsView',
        component: () => import(/* webpackChunkName: "about" */ '../views/system/systemOmologationsView.vue')
      },
      {
        path: 'system/variables/fuel-types',
        name: 'systemFuelTypesView',
        component: () => import(/* webpackChunkName: "about" */ '../views/system/systemFuelTypesView.vue')
      },
      {
        path: 'system/variables/tires',
        name: 'systemTiresView',
        component: () => import(/* webpackChunkName: "about" */ '../views/system/systemTiresView.vue')
      },
      {
        path: '/',
        name: 'dashboardMain',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/dashboardMain.vue')
      },
      {
        path: 'catalog/makes',
        name: 'makesView',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/makesView.vue')
      },
      {
        path: 'catalog/makes/:makeId/used-variables',
        name: 'usedVariablesView',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/usedVariablesView.vue')
      },
      {
        path: 'catalog/makes/:makeId/public-promo',
        name: 'publicPromoView',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/publicPromoView.vue')
      },
      {
        path: 'catalog/makes/:makeId/public-promo/:promoId',
        name: 'publicPromoViewEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/publicPromoViewEdit.vue')
      },
      {
        path: 'catalog/makes/:makeId/models',
        name: 'modelsView',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/modelsView.vue')
      },
      {
        path: 'catalog/makes/:makeId/models/:modelId/series',
        name: 'seriesView',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/seriesView.vue')
      },
      {
        path: 'catalog/makes/:makeId/models/:modelId/series/:serieId',
        name: 'serieView',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/singleSerieView.vue')
      },
      // routes series
      {
        path: 'catalog/series/:serieId/powertrains',
        name: 'seriePowertrainsView',
        component: () => import('../views/dashboard/serie/seriePowertrainsView.vue')
      },
      {
        path: 'catalog/series/:serieId/features',
        name: 'serieFeaturesView',
        component: () => import('../views/dashboard/serie/serieFeaturesView.vue')
      },
      {
        path: 'catalog/series/:serieId/my',
        name: 'serieMyView',
        component: () => import('../views/dashboard/serie/serieModelyearsView.vue')
      },
      {
        path: 'catalog/series/:serieId/my/:myId',
        name: 'singleMyView',
        component: () => import('../views/dashboard/serie/my/singleMyView.vue')
      },
      {
        path: 'catalog/series/:serieId/my/:myId/paint-schemes',
        name: 'myPaintsView',
        component: () => import('../views/dashboard/serie/my/myPaintsView.vue')
      },
      {
        path: 'catalog/series/:serieId/my/:myId/features-packs',
        name: 'myPacksView',
        component: () => import('../views/dashboard/serie/my/myPacksView.vue')
      },
      {
        path: 'catalog/series/:serieId/my/:myId/trims',
        name: 'myTrimsView',
        component: () => import('../views/dashboard/serie/my/myTrimsView.vue')
      },
      {
        path: 'catalog/series/:serieId/my/:myId/versions',
        name: 'myVersionsView',
        component: () => import('../views/dashboard/serie/my/myVersionsView.vue')
      },
      {
        path: 'catalog/series/:serieId/my/:myId/list-prices',
        name: 'myVersionsView',
        component: () => import('../views/dashboard/serie/my/myPriceListView.vue')
      },
      {
        path: 'catalog/series/:serieId/my/:myId/campaigns',
        name: 'myCampaignsView',
        component: () => import('../views/dashboard/serie/my/myCampaignsView.vue')
      },
      {
        path: 'catalog/series/:serieId/my/:myId/campaigns/:campaignId',
        name: 'mySingleCampaignView',
        component: () => import('../views/dashboard/serie/my/mySingleCampaignView.vue')
      },
      {
        path: 'catalog/series/:serieId/my/:myId/variables',
        name: 'myVariablesView',
        component: () => import('../views/dashboard/serie/my/myVariablesView.vue')
      },
      {
        path: 'catalog/series/:serieId/my/:myId/components-relations',
        name: 'myComponentsRelationsView',
        component: () => import('../views/dashboard/serie/my/myComponentsRelationsView.vue')
      },
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
