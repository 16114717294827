import api from "./api";
import TokenService from "./token.service";
class AuthService {
    async login(parameters: { email: string, password: string }) {
        try {
            const response = await api.post("/auth/login", {
                email: parameters.email,
                password: parameters.password
            })
            if (response.data.token) {
                TokenService.setUser(response.data.user);
                TokenService.setRefreshToken(response.data.refreshToken);
                TokenService.setToken(response.data.token);
            }
            return response.data;
        } catch (ex: any) {
            let message = 'error'
            if (ex.response.status === 401)
                message = ex.response.data.message
            else
                message = `Impossibile eseguire l'operazione`
            throw (message)
        }
    }
    async logout() {
        try {
            const response = await api.post("/auth/logout", {
                logoutType: 'all',
                currentRefreshToken: TokenService.getLocalRefreshToken()
            })
            TokenService.removeAllTokens()
            TokenService.removeUser();
        } catch (ex: any) {
            let message = 'error'
            if (ex.response.status === 401)
                message = ex.response.data.message
            else
                message = `Impossibile eseguire l'operazione`
            throw (message)
        }
    }
}
export default new AuthService();