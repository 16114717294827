import { User } from "@/common/interfaces/user.interface";

class TokenService {
  getLocalRefreshToken() {
    const refreshToken = JSON.parse(String(localStorage.getItem("refreshToken")));
    return refreshToken;
  }
  getLocalAccessToken() {
    const token = JSON.parse(String(localStorage.getItem("token")));
    return token;
  }
  getUser() {
    return JSON.parse(String(localStorage.getItem("user")));
  }
  setUser(user: User) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  setToken(token: string) {
    localStorage.setItem("token", JSON.stringify(token));
  }
  setRefreshToken(refreshToken: string) {
    localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
  }
  removeUser() {
    localStorage.removeItem("user");
  }
  removeAllTokens() {
    localStorage.removeItem("token")
    localStorage.removeItem("refreshToken")
  }
}
export default new TokenService();