
import authService from '@/services/auth.service'
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    async logOut() {
      try {
        await authService.logout()
        this.$router.push('/')
      } catch (ex) {
        console.log(ex)
        this.$toast.error(String(ex))
      }
    }
  }
})
