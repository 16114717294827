import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Toast from "vue-toastification";
import { dayJsPlugin } from './plugins/dayjs'
import "vue-toastification/dist/index.css";

import Vuex from 'vuex';
import setupInterceptors from './services/setupInterceptors';
const optionsToast = {
  position: 'top-right',
  hideProgressBar: true
};

import VCurrencyField from 'v-currency-field'

Vue.use(VCurrencyField, {
  locale: 'it-IT',
  decimalLength: 2,
  autoDecimalMode: true,
  min: -21474836,
  max: 21474836,
  defaultValue: 0,
  valueAsInteger: true,
  allowNegative: true,
  suffix: '€'
})

Vue.config.productionTip = false
Vue.use(Toast, optionsToast);
Vue.use(Vuex);
Vue.use(dayJsPlugin)
setupInterceptors(store);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
