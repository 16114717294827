
import Vue from 'vue'
import authService from '@/services/auth.service'

export default Vue.extend({
  data: () => ({
    email: '',
    password: '',
    validFormLogin: true,
    validFormReset: true,
    resetPassword: false,
    emailReset: ''
  }),
  methods: {
    login() {
      authService.login({
        email: this.email,
        password: this.password
      })
    },
    closeForgotPassword() {
      this.resetPassword = false
    },
    async forgotPassword() {
      console.log('reset')

    },
    async doLogin() {
      try {
        let obj = {
          email: this.email,
          password: this.password
        }
        let authResp = await authService.login({
          email: this.email,
          password: this.password
        })
        if (authResp.success) {
          this.$router.push('/dashboard')
        }
      } catch (ex) {
        this.$toast.error(String(ex))
      }
    }
  }
})
